//colors
$white: #fff;
$off-white: #e9e9e9;
$offer-white: #e1e1e1;
$purple: #464E5E;
$bright-red: #ff6860;
$red: #C85F58;
$red-incorrect: #B14C49;
$green: #08BA69;
$body-blue: #367D89;
$black: #333333;
$yellow: #FFC106;
$lightPurple: #503D5D;
$darkGray: #454545;
$mediumGray: #666666;
$flash-blue: #5592A1;
$darkYellow: #E0A800;

//cutpoints mixins
@mixin media-breakpoint-down($size) {
	@if ($size == 'xs') {
		@media (max-width: 575.98px) {
			@content;
		}
	} @else if ($size == 'sm') {
		@media (max-width: 767.98px) {
			@content;
		}
	} @else if ($size == 'md') {
		@media (max-width: 991.98px) {
			@content;
		}		
	} @else if ($size == 'lg') {
		@media (max-width: 1199.98px) {
			@content;
		}		
	} @else if ($size == 'xl') {
		@media (min-width: 1200px) {
			@content;
		}		
	}
}

//phone size mixins
@mixin phone-resize($phone) {
	@if ($phone == 8) {
		@media (max-device-height : 667px) {
		    @content;
		}
	}
}

//modal styling override
.modal-content {
	border-radius: 0;
	//border: 3px solid $black;
	background: $purple;
	box-shadow: 5px 5px $offer-white;
	
	.modal-header {
		background: $purple;
		color: $white;
		border-radius: 10px;
		border-bottom: none;

		.modal-title {
			font-weight: bold;
		}
		
		.close,
		.close:hover {
			color: $white;
		}
	}
	
	.modal-body {
		background: $purple;
		color: $white;
	}
	
	.modal-footer {
		background: $purple;
		border-radius: 10px;
		border-top: none;
		
		.btn {
			border-radius: 0;
		}
	}
}

.row.noMargin {
	margin-left: 0;
	margin-right: 0;
}

.hidden {
	display: none;
}