@import '_shared';

//announce styling
#Announce {
	position: relative;

	//set nickname
	.setNick {
		text-align: center;
		background: $purple;
		margin: 30px 20px;
		padding: 10px;
		border-radius: 10px;
		color: $white;

		@include media-breakpoint-down(xs) {
			margin: 20px 0;
			border-radius: 0;
		}

		div {
			font-weight: bold;
			font-size: 18px;

			> span {
				display: block;
				font-weight: normal;
				padding-bottom: 8px;
				font-style: italic;
				font-size: 1rem;
			}
		}

		input {
			border: none;
			background: rgba(255,255,255,0.3);
			font-size: 24px;
			padding: .5rem;
			border-radius: 6px;
			line-height: 1;
			font-weight: bold;
			color: $white;
			text-align: center;

			@include media-breakpoint-down(xs) {
				width: 100%;
			}
		}

		button {
			margin-top: 10px;
		}
	}

	//display nick
	.nickDisplay {
		text-align: right;

		> div {
			display: inline-block;
			border: 2px solid $purple;
			border-radius: 20px;
			color: $purple;
			padding: 6px 16px;
			background: $offer-white;
			margin-top: 10px;
			position: relative;

			div.edit {
				display: inline-block;
				color: $red-incorrect;
				margin-right:6px;
				cursor: pointer;
			}

			span {
				font-weight: bold;
			}
		}

		@include media-breakpoint-down(md) {
			text-align: left;
			font-size: .75rem;
		}
	}

	//announcement styling
	.announcement {
		text-align: center;
		padding-top: 40px;
		margin-bottom: -10px;

		> div {
			max-width: 75%;
			margin: 0 auto;
			padding: 8px 10px;
			background: $purple;
			color: $white;
			font-size: 1rem;
			box-shadow: 5px 5px $offer-white;

			@include media-breakpoint-down(sm) {
				max-width: inherit;
				width: 100%;
				margin-bottom: 20px;
			}

			@include media-breakpoint-down(xs) {
				margin-bottom: 60px;
			}

			> p:last-child {
				padding-bottom: 0;
				margin-bottom: 0;
			}
		}

		a {
			color: $bright-red;

			&:hover {
				color: $red;
			}
		}
	}

	//live trivia display
	.scoreDisplay {
		position: relative;
		z-index: 1002;

		> div {
			position: absolute;
			top: 30px;
			left: calc(50% - 250px);
			width: 500px;
			background: $purple;
			border-radius: 10px;
			padding: 20px;
			color: $white;
			min-height: 70vh;
			z-index: 200;
			font-size: 18px;

			@include media-breakpoint-down(sm) {
				width: auto;
				left: 15px;
				right: 15px;
			}

			table {
				list-style-type: none;
				margin: 0;
				width: 100%;

				th {
					padding: 0 10px 8px 10px;
				}

				td {
					padding: 8px 10px;
					font-weight: bold;
					border-top: 1px solid $white;
					border-bottom: 1px solid $white;
				}

				tr:nth-child(odd) td {
					background: rgba(255,255,255,0.3);
				}

				tr:first-child td {
					border-top: none;
				}

				tr:last-child td {
					border-bottom: none;
				}
			}
		}
	}
}

//computer styling
#Computer {
	position: relative;

	@include media-breakpoint-down(md) {
		padding-left: 0;
		padding-right: 0;
	}

	&:before {
		content: "";
		position: absolute;
		z-index: 1000;
		background: url('../img/Robot.png') top left no-repeat;
		background-size: contain;
		width: 94px;
		height: 124px;
		left: calc(50% - 220px);
		top: 27px;
		
		@include media-breakpoint-down(sm) {
			top: -23px;
			left: calc(50% + 130px);
		}
		
		@include media-breakpoint-down(xs) {
			top: -63px;
			right: 15px;
			left: inherit;
		}
	}
	
	.computerCol {
		padding-top: 90px;

		@include media-breakpoint-down(sm) {
			padding-top: 40px;
		}
		
		@include media-breakpoint-down(xs) {
			padding-top: 10px;
		}

		#score {
			position: relative;
			margin: 0 auto;
			padding: 4px;
			width: 200px;
			background: $darkGray;
			font-weight: bold;
			color: $white;
			border-radius: 6px 6px 0 0;

			transition: transform .3s ease;
			transform: translateY(50px);

			&.active {
				transform: translateY(0);
			}

			&.isLive {
				transform: translateY(0);
			}

			@include media-breakpoint-down(xs) {
				z-index: 1;
				transform: translateY(0);
				opacity: 0;
				border-radius: 0 0 6px 6px;
				padding: 2px;
				font-size: 12px;

				&.active {
					transform: translateY(42px);
					opacity: 1;
				}

				&.isLive {
					transform: translateY(42px);
					opacity: 1;					
				}
			}
		}
	}

	.computer {
		display: inline-block;

		@include media-breakpoint-down(sm) {
			display: block;
		}

		> .screen {
			position: relative;
			width: 500px;
			background: $red;
			border: 20px solid $purple;
			border-radius: 10px;

			.timeRemaining {
				position: absolute;
				font-size: 14px;
				bottom: 18px;
				width: 100%;
				text-align: center;
				color: $white;
				font-weight: bold;
			}

			@include media-breakpoint-down(sm) {
				width: 100%;
			}

			&:before,
			&:after {
				content: "";
				position: absolute;
				left: 50%;			
			}

			&:before {
				top: -10px;
				margin: -3px 0 0 -3px;
				width: 6px;
				height: 6px;
				border-radius: 6px;
				background: #a5adbd;
			}

			&:after {
				width: 8px;
				height: 8px;
				border-radius: 8px;
				bottom: -10px;
				margin: 0 0 -4px -4px;
				background: #e8ebf0;
			}

			.content {
				display: flex;
				flex-direction: column;
				justify-content: space-between;			
				width: 100%;
				overflow: hidden;
				text-align: left;
				color: $white;
				//position: absolute;

				> p {
					padding: 20px;
					min-height: 200px;

					@include media-breakpoint-down(xs) {
						padding-top: 30px
					}

					@include phone-resize(8) {
						font-size: .8rem;
						min-height: 160px;
					}

					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}

				//current answer in live trivia
				#revealAnswer {
					text-align: center;
					width: 100%;
					background: $green;
					color: $white;
					text-align: center;
					padding: 6px 10px;
					//font-style: italic;
					margin-bottom: -16px;
					margin-top: -1rem;
					z-index: 1;
					text-transform: uppercase;
					font-size: .85rem;

					> span {
						display: block;
						padding-top: 4px;
						font-weight: bold;
						font-style: normal;
						font-size: 20px;
						text-transform: none;

						> p {
							display: inline;
							margin: 0;
							padding: 0;
						}
					}
				}

				.progressContainer {
					.progress {
						border-radius: 0;

						.progress-bar {
							transition: width 1s linear;
						}
					}
				}
			}

			.base,
			.grey-shadow {
				&:before,
				&:after {
					content: "";
					position: absolute;
					top: 0;				
				}
			}

			.base {
				position: absolute;
				width: 90px;
				height: 50px;
				bottom: -70px;
				left: 50%;
				margin-left: -45px;
				background: #e8ebf0;

				&:before {
					border-left: 13px solid transparent;
					border-right: 0px solid transparent;
					border-bottom: 50px solid #e8ebf0;
					left: -13px;				
				}

				&:after {
					border-right: 13px solid transparent;
					border-left: 0px solid transparent;
					border-bottom: 50px solid #e8ebf0;
					right: -13px;
				}

				> div {
					position: absolute;
				}
			}

			.grey-shadow {
				width: 90px;
				height: 12px;
				background: #d8dbe1;
				top: 0;

				&:before {
					border-left: 3px solid transparent;
					border-right: 0px solid transparent;
					border-bottom: 12px solid #d8dbe1;
					left: -3px;
					z-index: 2;		
				}	

				&:after {
					border-right: 3px solid transparent;
					border-left: 0px solid transparent;
					border-bottom: 12px solid #d8dbe1;
					right: -3px;
					z-index: 2;			
				}
			}

			.foot {
				background: #e8ebf0;
				z-index: 1;

				&.top {
					width: 116px;
					height: 5px;
					bottom: -5px;
					left: 50%;
					margin-left: -58px;

					&:before,
					&:after {
						content: "";
						position: absolute;
						top: 0px;				
					}

					&:before {
						border-left: 16px solid transparent;
						border-right: 0px solid transparent;
						border-bottom: 5px solid #e8ebf0;
						left: -16px;
					}

					&:after {
						border-right: 16px solid transparent;
						border-left: 0px solid transparent;
						border-bottom: 5px solid #e8ebf0;
						right: -16px;
					}
				}

				&.bottom {
					width: 150px;
					height: 5px;
					bottom: -10px;
					left: 50%;
					margin-left: -75px;
					z-index: 999;
				}
			}
			
			@include media-breakpoint-down(xs) {
				.base,
				.grey-shadow,
				.foot {
					display: none !important;
				}

				margin-bottom: -3px;
			}
		}

		.roundInfo {
			position: relative;
			display: inline-block;
			z-index: 2;
			font-size: 1rem;
			margin: 0 auto;
			min-width: 200px;
			margin-bottom: -32px;
			transition: opacity .3s ease;
			opacity: 0;

			&.active {
				opacity: 1;
			}

			span.number {
				display: inline-block;
				font-style: normal;
				border-radius: 4px;
				border-radius: 0 0 0 6px;
				background: #606060;
				padding: 4px 6px;
				color: $white;
			}

			span.round {
				display: inline-block;
				font-style: italic;
				color: $white;
				background: $darkGray;
				border-radius: 0 0 6px 0;
				padding: 4px 12px;
			}

			@include media-breakpoint-down(xs) {
				font-size: 12px;
				
				span.number,
				span.round {
					padding: 2px 6px;
				}
			}
		}
	}

	.answerCol {
		padding-top: 100px;
		text-align: center;
		
		@include media-breakpoint-down(xs) {
			padding-top: 20px;	
		}

		@include phone-resize(8) {
			padding-top: 10px;
		}

		form {
			position: relative;
		}

		textarea {
			border: none;
			background: rgba(255,255,255,0.3);
			font-size: 24px;
			padding: .75rem 1rem;
			border-radius: 6px;
			width: 460px;
			line-height: 1.5;
			font-weight: bold;
			color: $white;
			text-align: center;
			transition: background .3s ease;
			resize: none;
			min-height: 60px;

			&:focus {
				outline: none;
			}

			&:disabled {
				-webkit-text-fill-color: $white;
				opacity: 1;
			}

			@include media-breakpoint-down(sm) {
				width: 100%;
			}

			&.correct {
				background: $green;
			}

			&.incorrect {
				background: $red;
			}

			&.shake {
				animation: shake .4s linear;
			}

			&.hasGuessed {
				background: $purple;
			}

			&.pulse {
				animation: pulse .45s linear;
			}
		}

		button#submit {
			border: none;
			background: $purple;
			text-align: center;
			width: 48px;
			height: 48px;
			line-height: 1.5;
			font-size: 22px;
			color: $white;
			border-radius: 6px;
			position: absolute;
			top: .375rem;
			left: calc(50% + 177px);
			opacity: .2;
			transition: opacity .2s ease;
			padding: 0;
			z-index: 1;

			@include media-breakpoint-down(sm) {
				left: calc(100% - 53px);
			}

			&.shake {
				animation: shake .4s linear;
			}

			&.correct {
				opacity: 0;
			}

			&:hover {
				opacity: .8;
				cursor: pointer;
			}
		}

		#answerStatus {
			position: absolute;
			top: .375rem;
			left: calc(50% + 177px);
			width: 48px;
			height: 48px;
			border-radius: 24px;
			border: 3px solid $white;
			color: $white;
			text-align: center;
			line-height: 1.95;
			font-size: 22px;
			padding: 0;
			z-index: 1;

			@include media-breakpoint-down(sm) {
				left: calc(100% - 53px);
			}

			&.disabled {
				display: none;
			}

			&.correct {
				display: block;
				background: $green;
			}

			&.half {
				display: block;
				background: $darkYellow;
				font-weight: bold;
			}

			&.incorrect {
				display: block;
				background: $red;
			}
		}

		#guessStatus {
			color: $white;
			padding-top: 6px;
			font-style: italic;

			p {
				margin: 0;
			}

			.yourAnswer {
				margin-bottom: 4px;
				word-break: break-word;
			}

			.message {

			}
		}
	}

	&.isLive {
		.computer {
			> .screen {
				
			}
		}
	}
}

@keyframes shake {
	15%, 55% {
		transform: translateX(-10px);
	}
	35%, 75% {
		transform: translateX(10px);
	}
	0%, 100% {
		transform: translateX(0);
	}
}

@keyframes pulse {
	0% {
		background: $purple;
	}
	50% {
		background: #666;
	}
	100% {
		background: $purple;
	}
}

@keyframes bg-flash {
	0% {
		background: $body-blue;
	}
	50% {
		background: $flash-blue;
	}
	100% {
		background: $body-blue;
	}	
}

#root.bg-flash {
	animation: bg-flash .75s ease-in-out;
	animation-iteration-count: 1;
}