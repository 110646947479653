@import '_shared.scss';

#adminInterface {
	padding-top: 40px;

	@include media-breakpoint-down(lg) {
		.btn {
			padding: .375rem 0.5rem;
		}
	}

	.innerCol {
		border-radius: 10px;
		padding: 0 10px 10px 10px;
		color: $white;
		margin: 26px 0;

		&:first-child {
			margin-top: 0;
		}

		.title {
			position: relative;
			display: inline-block;
			font-weight: bold;
			top: -16px;
			padding: 6px 16px;
			background: $white;
			color: $black;
			border: 3px solid $purple;
			border-radius: 20px;
			overflow: hidden;

			span.liveOnline {
				display: inline-block;
				background: $green;
				color: $white;
				font-size: .75rem;
				padding: 2px 6px;
				border-radius: 6px;
				margin-left: 6px;
				margin-top: 1px;
				float: right;
			}
		}

		&.announcement {
			background-color: rgba(255,255,255,0.3);

			.title {
				border-color: $black;
			}

			textarea {
				width: 100%;
				height: 90px;
				border-radius: 10px;
				border-color: $off-white;
				margin-bottom: 10px;
				padding: 10px;
				font-size: .8rem;
			}

			button {
				margin-left: 10px;
			}

			.area {
				padding-bottom: 0;
			}
		}

		&.trivia {
			background-color: $purple;
			//min-height: 360px;
			
			.title {
				border-color: $black;
			}

			.theQuestion {
				margin-top: 10px;
				padding: 6px 8px 0 8px;
				font-weight: bold;
				background: rgba(255,255,255,0.2);
				border-radius: 10px 10px 0 0;

				p {
					margin-bottom: 0;
				}
			}

			.theAnswer {
				padding: 0;
				background: rgba(255,255,255,0.2);
				border-radius: 0 0 10px 10px;

				> p {
					margin: 0;
					padding: 0px 8px 6px 8px;
				}

				.theNotes {
					> p {
						margin: 4px 0 0 0;
						padding: 4px 8px 4px 8px;
						background: rgba(255,255,255,0.2);
						border-radius: 0 0 10px 10px;
					}
				}
			}

			.liveQuestion {
				font-weight: bold;
				color: #ffd500;
				text-align: center;
				padding-top: 10px;
				font-size: 1.25rem;
			}
		}

		&.standings {
			background-color: $darkGray;
			min-height: 100px;

			.scoreDisplay {
				padding: 0 6px;
			}

			table {
				list-style-type: none;
				margin: 0 0 10px 0;
				width: 100%;

				th {
					padding: 0 6px 4px 6px;
					font-size: 14px;

					&.score {
						text-align: right;
					}
				}

				td {
					padding: 4px 6px;
					font-weight: bold;
					border-top: 1px solid rgba(255,255,255,0.2);
					font-size: 14px;
				}

				tr td:last-child {
					text-align: right;
				}

				tr:nth-child(odd) td {
					background: rgba(255,255,255,0.15);
				}

				tr:first-child td {
					border-top: none;
				}

				tr:last-child td {
					border-bottom: none;
				}

				//borders radiuses
				tr:last-child td {
					&:first-child {
						border-bottom-left-radius: 6px;	
					}

					&:last-child {
						border-bottom-right-radius: 6px;
					}
				}

				tr:nth-child(1) td {
					&:first-child {
						border-top-left-radius: 6px;	
					}

					&:last-child {
						border-top-right-radius: 6px;
					}
				}
			}

			.notLive {
				background: $mediumGray;
				font-style: italic;
				padding: 4px 6px;
				text-align: center !important;
				border-radius: 6px;
				font-weight: normal;
			}
		}

		&.players {
			background-color: #999;

			ul {
				list-style-type: none;
				margin: 10px 0 0 0;
				padding: 0;
				display: flex;
				flex-wrap: wrap;
				//overflow: hidden;

				li {
					background: $purple;
					border-radius: 6px;
					margin: 0 4px 24px 4px;
					//width: calc(50% - 8px);
					flex-basis: calc(50% - 8px);
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;

					@include media-breakpoint-down(sm) {
						flex-basis: 100%;
					}

					.email {
						font-weight: bold;
						font-size: .85rem;
						padding-top: 2px;
						margin-left: 10px;
						text-align: center;
						padding-bottom: 4px;
						word-break: break-word;
					}

					.score {
						font-size: .85rem;
						margin-top: -16px;
						background: $purple;
						color: $white;
						width: 106px;
						text-align: center;
						padding: 2px 4px;
						border-radius: 4px;
						font-weight: bold;
						position: relative;

						&:before {
							content: "";
							width: 6px;
							height: 6px;
							border-radius: 12px;
							background-color: $purple;
							display: block;
							position: absolute;
							top: 4px;
							left: 4px;
							transition: background-color .3s ease-in-out;				
						}
					}

					&.online .score:before {
						background-color: $green;
					}

					.answer {
						background: rgba(0,0,0,0.2);
						padding: 8px 4px 8px 4px;
						text-align: center;
						flex-grow: 1;
						border-radius: 0 0 6px 6px;

						span {
							font-weight: bold;
							word-break: break-word;
						}

						&.present {
							border-radius: 0;
						}
					}

					.scoreButtons {
						display: none;

						.markCorrect,
						.markIncorrect,
						.markHalfPoint {
							flex-grow: 1;
							flex-basis: 0;
							text-align: center;
							padding: 4px 0;
							font-size: .85rem;
							font-weight: bold;
							text-transform: uppercase;
							cursor: pointer;
							background: $mediumGray;

							&:hover {
								text-decoration: underline;
							}
						}

						.markCorrect {
							border-radius: 0 0 0 6px;
						}

						.markIncorrect {
						}

						.markHalfPoint {
							border-radius: 0 0 6px 0;
						}

						&.active {
							display: flex;

						}
					}

					&.correct {
						background: $green;

						.markCorrect {
							background: $green;

							&:hover {
								text-decoration: none;
							}
						}
					}

					&.incorrect {
						background: $red;

						.markIncorrect {
							background: $red;

							&:hover {
								text-decoration: none;
							}
						}
					}

					&.halfPoint {
						background: $darkYellow;

						.markHalfPoint {
							background: $darkYellow;

							&:hover {
								text-decoration: none;
							}
						}
					}
				}
			}
		}

		&.controls {
			background-color: $red;

			button {
				&.btn-primary {
					background-color: $purple;
					border-color: $purple;
				}
			}

			.area {
			}
		}

		.progressContainer {
			.progress {
				border-radius: 0;
				height: 1.25rem;

				.progress-bar {
					transition: width 1s linear;
				}
			}
		}

		//shared
		.area {
			padding: 0 6px 20px 6px;

			label {
				font-weight: bold;
				font-style: italic;
				display: block;
			}

			&.refresh {
				button {
					margin-right: 4px;
				
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		select {
			width: 100%;
			padding: 8px;
			border-radius: 10px;
			border: none;
		}
	}
}